.works__works{
    color:var(--secondary_fontColor);
}


.works__list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: auto;
    gap: 40px;

}
.works__container{
    padding-top: 5vh;
    padding-bottom: 5vh;
}
@media screen and (min-width: 780px) {

}