* {
  margin: 0;
  padding: 0;
}
img{
  overflow: hidden
  
}

@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400&display=swap');
:root {
  
  --secondary_bgColor: linear-gradient(to bottom right,rgb(151, 75, 0),rgb(0, 0, 56),rgb(0, 0, 56),rgb(0, 0, 56));
  --secondary_fontColor: rgb(245,138,30);
  --primary_fontColor: white;
  --tertiary_fontColor: black;
  --primary_bgColor: rgb(56,50,90);
  --card_bg: rgb(24,10,65);
  --primary_cardBg: rgb(152, 152, 152);
  background-color: var(--primary_bgColor);
  
  color: var(--primary_fontColor);
}

.container {
  /* padding-top: 5vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-family: "Signika", sans-serif;
  background-image: var(--secondary_bgColor); 

  margin-bottom: 40px;
  border-radius: 1.8rem;
  font-weight: 400;
  box-shadow: 10px 10px 30px rgba(17, 17, 17, 0.7);
}

.all__desc{
  font-style:oblique;
}

@media screen and (min-width: 780px) {
  .container {
    min-height: 100vh;
    width: 60vw;
    margin-left: 36vw;
  }
}
