a {
  text-decoration: none;
}

.hero__container {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Signika", sans-serif;
}
.hero__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  /* background-color: var(--secondary_bgColor); */
  background-image: var(--secondary_bgColor);
  border-bottom-left-radius: 50vw;
  border-bottom-right-radius: 50vw;
  box-shadow: 10px 10px 30px rgba(17, 17, 17, 0.7);
}
.hero__avatar {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  border: 2px solid var(--tertiary_fontColor);
  margin: 10px;
  background-color: rgb(214, 246, 255);
}

.hero__avatar:hover {
  border: 2px solid var(--secondary_fontColor);
}
.hero__social-icon {
  margin: 10px;
  font-size: 1.4rem;
  color: var(--primary_fontColor);
}
.hero__social-icon:hover {
  color: var(--secondary_fontColor);
}

.hero__resume {
  background-color: var(--secondary_bgColor);
  color: var(--primary_fontColor);
  border: 2px solid var(--primary_fontColor);
  border-radius: 40px;
  padding: 5px 12px;
  margin: 20px;
}
.hero__resume:hover {
  background-color: var(--primary_fontColor);
  color: var(--tertiary_fontColor);
  font-weight: bold;
}

.hero_element {
  color: var(--primary_fontColor);
  margin-top: 5px;
  margin-bottom: 5px;
}
.hero__my-position1 {
  color: rgb(182, 182, 182);
  font-size: 1.7rem;
}
.hero__my-name1 {
  font-size: 2.5rem;
}
@media screen and (min-width: 780px) {
  .hero__container {
    position: fixed;
    left: 17px;
    display: flex;
    flex-direction: column;
    height: 99vh;
    font-family: "Signika", sans-serif;
  }
  .hero__avatar {
    height: 10vw;
    width: 10vw;
    border-radius: 50%;
    border: 2px solid var(--primary_bgColor);
    margin: 10px;
  }
  .hero__left {
    margin-left: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25vh;
    width: 30vw;
    background-color: var(--secondary_bgColor);
    height: 99vh;
    border-bottom-left-radius: 50vw;
    border-bottom-right-radius: 50vw;
  }
}
/* @media screen and (orientation: landscape) {
  .hero__left {
    display: none;
  }
} */
