.about__skills-container {

  display: block;
  margin: 5vh 0;
}

.about__skills-logo {
  font-size: 2rem;
  font-weight: 600;
  margin: 2vh 0;
}
.about__skills-logo-p1 {
  margin-right: 10px;
}

.about__skills-logo-p2 {
  color: var(--secondary_fontColor)
}

.about__skills-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.about__skill {
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1vw;
  /* border: 2px solid white; */
  padding: 1vh;
  width: 150px;
  background-color: var(--card_bg);
}

.about__skill-icon {
  font-size: 2rem;
}
@media screen and (min-width: 780px) {
  .about__skill {
    border: none;
    width: 250px;
    padding: 3vh 1vh;
  }
  .about__skill {
    box-shadow: 7px 7px 15px rgb(25, 25, 25,0.7);
  }
  .skills__container {
    min-height: 65vh;
    padding-bottom: 2.9rem;
  }
}
